import React, { Component } from "react"; 
import config from "../configs"; 
import horizonService from "../services/HorizonService" 

export default class Login extends Component {


    constructor(props) {
        super(props);
        this.state = {username: '', password: '', domain: '', zone: '', csrfmiddlewaretoken:'', message: ''};
        this.handleUsername = this.handleUsername.bind(this);
        this.handlePass = this.handlePass.bind(this);
        this.handleDomain = this.handleDomain.bind(this);
        this.handleZone = this.handleZone.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount(){
    document.title = config.app.title;
    }
    handleUsername(event) {
    this.setState({username: event.target.value});
    }
    handlePass(event) {
    this.setState({password: event.target.value});  
    }
    handleDomain(event) {
        this.setState({domain: event.target.value});
    }
    handleZone(event) {
    this.setState({zone: event.target.value});
    }
    validateForm(){
    
        if(config.app.enableCredencial){
            if(this.state.username===""){
                this.setState({message:"Campo <b>nome</b> obrigatório."});
                return false;
            }
            if(this.state.password===""){
                this.setState({message:"Campo <b>password</b> obrigatório."});
                return false;
            }
            if(this.state.dormain===""){
                this.setState({message:"Campo <b>domínio</b> obrigatório."});
                return false;
            }
        }
        if(this.state.zone===""){
            this.setState({message:"Campo <b>Zona</b> obrigatório."});
            return false;
        }

        return true;
    }
    callback(response) {  
      
    }   
    callbackError(error) { 
        console.log('error ', error);
    }
    handleSubmit(event) {
        event.preventDefault();
        this.setState({message:""});

        
        if(this.validateForm()){
           horizonService(this.state ,this.callback, this.callbackError);
        }
    }

    render() {

        const haveCredentias = config.app.enableCredencial; 
     
        if(haveCredentias) {
            return (
                <form  onSubmit={this.handleSubmit}>
                    <h3>{config.app.title_page}</h3>
                    <div className="form-group ">
                        <p className="logo-container text-center">
                            <img alt={"Logo Embratel"} className="Logo" src={"images/logo-embratel.png"} />
                        </p>
                    </div>
                    
                    <div className="form-group">
                        <label>Usuário</label>
                        <input name={"username"}   value={this.state.username} onChange={this.handleUsername} type="text" className="form-control" placeholder="Insira seu usuário"    />
                    </div>
     
                    <div className="form-group">
                        <label>Password</label>
                        <input name={"pass"}    value={this.state.password} onChange={this.handlePass}  type="password" className="form-control" placeholder="Insira seu password"  />
                    </div>
    
                    <div className="form-group">
                        <label>Domínio</label>
                        <input name={"domain"}  type="text" value={this.state.domain} onChange={this.handleDomain}   className="form-control" placeholder="Insira seu domínio" />
                    </div>
    
                    <div className="form-group">
                        <label>Zona</label>
                        <select name={"zone"} value={this.state.zone} onChange={this.handleZone}  className="form-control" > 
                            <option value={""}>Selecione</option>
                            { config.zones.map((data,index) => <option key={index} value={data.code}>{data.name}</option>)}
                        </select>
                    </div>
                    <div className="form-group ">
                        <p className="text-center error" dangerouslySetInnerHTML={{ __html: this.state.message  }} />  
                       
                    </div>
    
                    <div className="form-group">
                        <p className="bt-submit-container text-center">
                            <button type="submit" className="btn btn-primary btn-block">Entrar</button>
                        </p>
                    </div>          
                </form>
            );
        } else {
            return (
            <form  onSubmit={this.handleSubmit}>
                    <h3>{config.app.title_page}</h3>
                    <div className="form-group ">
                        <p className="logo-container text-center">
                            <img alt={"Logo Embratel"} className="Logo" src={"images/logo-embratel.png"} />
                        </p>
                    </div>
                    <div className="form-group">
                        <label>Zona</label>
                        <select name={"zone"} value={this.state.zone} onChange={this.handleZone}  className="form-control" > 
                            <option value={""}>Selecione</option>
                            { config.zones.map((data,index) => <option key={index} value={data.code}>{data.name}</option>)}
                        </select>
                    </div>
                    <div className="form-group ">
                        <p className="text-center error" dangerouslySetInnerHTML={{ __html: this.state.message  }} />  
                       
                    </div>
    
                    <div className="form-group">
                        <p className="bt-submit-container text-center">
                            <button type="submit" className="btn btn-primary btn-block">Entrar</button>
                        </p>
                    </div>          
                </form>
            )
        } 
    }
        
}