 
import config from "../configs"; 
import { VirtualForm, Util } from "../helpers";

const HorizonService = ( data, callback, callbackError ) => {
    const urlLogin = config.zones.find(item => item.code === data.zone).baseUrl;
    const fields =  (config.app.enableCredencial?[{ type:'hidden',name:'k', value: Util.cipher(data) }]:[]);
    const formHorizon = new VirtualForm(urlLogin,'GET','_SELF' , fields );
    formHorizon.submit();
 }

export default HorizonService;