const getCsrToken = (data) => { 
    return  data.find((item)=> item.tagName === "html")
            .children.find((item)=> item.tagName === "body")
            .children.find((item)=> item.tagName === "div")
            .children.find((item)=> item.tagName === "div")
            .children.find((item)=> item.tagName === "div") 
            .children.find((item)=> item.tagName === "form") 
            .children.find((item)=> item.tagName === "input") 
            .attributes.find((item)=> item.key === "value").value;
}

export default getCsrToken;