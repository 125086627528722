class VirtualForm {

 
    constructor(url, method, target, fields){
        this.url = url;
        this.method = method;
        this.target = target;
        this.fields = fields;
        this.form = null;

        this.create();
    }

    create(){  
            // Create a form synamically
            var form = document.createElement("form");
            form.setAttribute("method", this.method );
            form.setAttribute("action", this.url );
            form.setAttribute("target", this.target );
            form.setAttribute("style", "display:none" );

             

            // Create an input element for domain
            this.fields.forEach(element => {
                var CSR = document.createElement("input");
                    CSR.setAttribute("type", element.type); 
                    CSR.setAttribute("name", element.name);
                    CSR.setAttribute("value", element.value);
                    form.appendChild(CSR); 
            });
            
            
            // create a submit button
            var s = document.createElement("input");
                s.setAttribute("type", "submit");
                s.setAttribute("value", "Submit");
                
            form.appendChild(s); 

            this.form  = form;

            document.getElementsByTagName("body")[0].appendChild(form);
                    
    }

    submit(){
       this.form.submit();
    }
}

export default VirtualForm;