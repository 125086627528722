import CryptoJS from "crypto-js";
import config from "../configs";

class Util {


    static setCookie(cname, cvalue) {
        const exdays = 2
        const d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        let expires = "expires="+d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    static getCookie(cname) {
        let name = cname + "=";
        let ca = document.cookie.split(';');
        for(let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) === ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
    }

    static cipher = salt => {
                 
        var data = salt

        // Encrypt
        var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data),config.app.secret).toString();
        return ciphertext;
    }
 

}

export default Util;